
import { mapGetters } from 'vuex';
import { EventBus } from '~/components/eventBus/EventBus';
import { SECOND } from '~/vars/api';
import BaseSidebarItem from './BaseSidebarItem.vue';
export default {
  components: { BaseSidebarItem },
  props: {
    counter: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pendingMessageNotifications: 0,
      notifications: [],
      titleInterval: null,
      previousTitle: '',
    };
  },
  computed: {
    ...mapGetters({
      getConnectionsNotificationsCounter:
        'appNotifications/getConnectionsNotificationsCounter',
    }),
    navigationOptionMessages() {
      return {
        icon: 'messages',
        title: this.$t('routes.connections'),
        key: 'messages',
        to: this.localePath({ name: 'messages' }),
        counter: this.getConnectionsNotificationsCounter.total,
      };
    },
    /*
    unreadNotifications() {
      this.getPendingMessageNotifications();
      return this.pendingMessageNotifications;
    },
    */
  },
  /*
  watch: {
    pendingMessageNotifications() {
      if (!this.previousTitle) {
        this.previousTitle = document.title;
      }
      clearInterval(this.titleInterval);
      if (this.unreadNotifications > 0) {
        let toggleTitle = false;
        this.titleInterval = setInterval(() => {
          const title = `(${this.unreadNotifications}) News in Messages`;
          document.title = toggleTitle ? title : this.previousTitle;
          toggleTitle = !toggleTitle;
          if (this.unreadNotifications === 0) {
            clearInterval(this.titleInterval);
            document.title = this.previousTitle;
          }
        }, 3000);
      } else {
        document.title = this.previousTitle;
      }
    },
  },
  */
};
